$(document).ready(function() {
	if(!document.querySelector('.animation-box')) return false;

	var ani = {
		el: document.querySelectorAll('.animation-box'),
		init: function(){
			ani.addEvent();
		},
		/* LGEITF-14 : 20200820 remove */
		/*videoEvent : function(e){
			var video = e.currentTarget,
				$ctrl = $(video).siblings('.controller-wrap');
			
			 PJTWAUS-1 : 20191220  add, LGEITF-14 : 20200821 modify 
			if(e.type == 'ended' || e.type == 'pause') {
				$ctrl.find('.play').addClass('active').siblings().removeClass('active');
			}else if(e.type == 'play' || e.type == 'playing') {
				$ctrl.find('.pause').addClass('active').siblings().removeClass('active');
			}
			 PJTWAUS-1 : 20191220  add, LGEITF-14 : 20200821 modify 
		},*/
		/*// LGEITF-14 : 20200820 remove */
		addEvent: function(){
			var _el = ani.el;
			/* LGEUS-254 20200409*/
			$(_el).each(function () {
				var load = true;
				var target = this; 
				if($(target).parents('section.component').find('.carousel-wrap').length>0) {
					target = $(target).parents('section.component').find('.carousel-wrap');
					if($(target).parents('section.component').find('.carousel-wrap').children().length > 1){
						load = false;
					}					
				}
				/* LGEITF-14 : 20200820 add, modify */
				ani.lazyload($(target), load); //init load
				
				$(window).resize(function(){ // resize
					ani.lazyload($(target), true);
				});
				
				$(target).off('click.palyBtn').on('click.palyBtn', '.controller-wrap button', function(e){ //play/pause button click
					
					var button = e.currentTarget;
					var box = $(button).closest('.animation-box').find('video:visible')[0];
					
					if(button.name == 'pause') {
						box.pause();
					}else if (button.name == 'play') {
						box.play();
					}
					/* PJTWAUS-1 : 20191220  add */
					var adobeLinkArea =  $(button).data('adobe-link-area');
					var adobeLinkName =  $(button).data('adobe-link-name');
					var playText = $(button).data('play-text');
					var pauseText = $(button).data('pause-text');
					if($(button).hasClass('pause')) {
						$(button).addClass('play').removeClass('pause').attr('name', 'play').attr('aria-label',playText).attr('data-link-area', adobeLinkArea).attr('data-link-name', adobeLinkName).text(playText);
					}else if($(button).hasClass('play')) {
						$(button).addClass('pause').removeClass('play').attr('name', 'pause').attr('aria-label',pauseText).removeAttr('data-link-area').removeAttr('data-link-name').text(pauseText);
					}
				});
				
				/* LGEITF-14 : 20200820 remove */
				/*$(target).find('video').on({
					'play playing pause ended': ani.videoEvent
				});
				$(target).on({
					'init': function(){
						$(target).find('video').on({
							'play playing pause ended': ani.videoEvent
						});
					}
				});*/
				/*// LGEITF-14 : 20200820 remove */
				
				
				/*// LGEITF-14 : 20200820 add, modify */
				/* PJTWAUS-1 : 20191220 add , LGEITF-14 : 20200820 modify */
				$(target).off('click.playAnimaion').on('click.playAnimaion', '.play-animaion-btn', function (e) { //acc button click
					var _this = $(this);
					var adobeLinkArea = _this.siblings('.animation-area:visible').find('.wa-btn button').data('adobe-link-area');
				    var adobeLinkName = _this.siblings('.animation-area:visible').find('.wa-btn button').data('adobe-link-name');
					if(_this.hasClass('acc-btn')){
					    var aniText = _this.data('ani-text');					    
					    _this.attr('aria-label', aniText).addClass('ani-btn').removeClass('acc-btn').attr('data-link-area', adobeLinkArea).attr('data-link-name', adobeLinkName).text(aniText);
					    
					    _this.siblings('.acc-animation-content').show();    
					    _this.siblings('.animation-content').hide();
					    var box = $(_this).parents('.animation-box').find('video:visible')[0];
					    ani.lazyload($(_this).parents('.animation-box'), true);
					    box.play();
					}else{
					    var accAniText = _this.data('acc-ani-text');
					    _this.attr('aria-label', accAniText).addClass('acc-btn').removeClass('ani-btn').attr('data-link-area', adobeLinkArea).attr('data-link-name', adobeLinkName).text(accAniText);
					    _this.siblings('.animation-content').show();    
					    _this.siblings('.acc-animation-content').hide();
					    var box = $(_this).parents('.animation-box').find('video:visible')[0];
					    ani.lazyload($(_this).parents('.animation-box'), true);
						box.play();
					}
					var button =  $(_this).parents('.animation-box').find('video:visible').eq(0).siblings('.controller-wrap').find('button');
				    var adobeLinkArea =  $(button).data('adobe-link-area');
					var adobeLinkName =  $(button).data('adobe-link-name');
					var playText = $(button).data('play-text');
					var pauseText = $(button).data('pause-text');
					$(button).addClass('pause').removeClass('play').attr('name', 'pause').attr('aria-label',pauseText).removeAttr('data-link-area').removeAttr('data-link-name').text(pauseText);
					e.preventDefault();
				});
				/*// PJTWAUS-1 : 20191220 add , LGEITF-14 : 20200820 modify */
			});
			/*//LGEUS-254 20200409*/
		},
		/* LGEITF-14 : 20200820 add */
		lazyload : function(target, load){
			var video = target.find('video:visible');
			$(video).each(function (idx) {
				var source = $(video[idx]).find('source');
				if(!!!source.attr('src')){
					source.attr('src', source.data('src'));
					console.log(video[idx]);
					if(load){
						video[idx].load();
					}					
				}
			});	
		}
		/* LGEITF-14 : 20200820 add */
	};
	ani.init();
});
